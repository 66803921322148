/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/scss/bootstrap";

@import "~@angular/material/prebuilt-themes/indigo-pink.css";


// Import library functions for theme creation.
@import '~@angular/material/theming';

// Include non-theme styles for core.
@include mat-core();

// Define the application's custom theme
$primary: mat-palette($mat-indigo);
$accent:  mat-palette($mat-pink, A200, A100, A400);
$theme: mat-light-theme($primary, $accent);

// Regular (light theme)
// Include theme styles for Angular Material components
// and include theme styles for your custom components.
@include angular-material-theme($theme);

// Define the application's dark theme
$primary-dark: mat-palette($mat-teal);
$accent-dark:  mat-palette($mat-teal, A200, A100, A400);

// Use mat-dark-theme instead of mat-light-theme
$dark-theme: mat-light-theme($primary-dark, $accent-dark);

.dark {
    @include angular-material-theme($dark-theme);
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

